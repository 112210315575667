<template>
  <div class="app-container">
    <a-modal title="素材中心" :visible="dialogVisible" :modal-append-to-body="false" width="70%"
      :footer="null" @cancel="dialogVisible = false">
      <a-tabs default-active-key="1">
        <a-tab-pane key="1" tab="图片"> </a-tab-pane>
      </a-tabs>

      <a-row :gutter="10">
        <a-col :xs="24" :sm="24" :md="4" :lg="4">
          <el-menu default-active="全部" class="el-menu-vertical-demo">
            <el-menu-item index="全部">
              <div style="display: flex;align-items: center;justify-content: space-between;"
                @click="handleTreeClick(undefined)">
                <div slot="title">全部图片</div>

                <template>
                  <el-button type="text" size="mini" @click="insterImageCate()">
                    <i class="el-icon-circle-plus-outline" style="font-size:18px"></i>
                  </el-button>
                </template>
              </div>
            </el-menu-item>

            <el-menu-item :index="index" v-for="(item, index) in treeList" :key="index">
              <div style="display: flex;align-items: center;justify-content: space-between;"
                @click="handleTreeClick(item.id)">
                <div slot="title">{{ item.categoryName }}</div>

                <template>
                  <a-dropdown>
                    <a class="ant-dropdown-link" @click.prevent>
                      <el-button type="text" size="mini">
                        <i class="el-icon-more" style="font-size:18px"></i>
                      </el-button>
                    </a>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item @click="updateCategory(item)">
                          <a-icon type="edit" /> 编辑分类
                        </a-menu-item>
                        <a-menu-item @click="remove(item.id)">
                          <a-icon type="delete" /> 删除分类
                        </a-menu-item>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </template>
              </div>
            </el-menu-item>
          </el-menu>
        </a-col>

        <a-col :xs="24" :sm="24" :md="20" :lg="20">
          <a-row>
            <!-- 按钮 -->
            <a-col :span="12">
              <div class="table-operations">
                <a-button v-if="selectedRowKeys" @click="selectImage()">
                  使用已选中的图片
                </a-button>

                <a-button @click="toPicturesImg" type="primary">
                  上传图片
                </a-button>

                <a-button @click="batchDeleteImgList()" type="danger">
                  删除图片
                </a-button>
              </div>
            </a-col>

            <!-- 查询 -->
            <a-col :span="12">
              <div style="float: right;">
                <div style="display: inline-block;">
                  <a-input-search v-model="queryParam.filenameSaved" placeholder="请输入图片名称查询" style="width: 200px"
                    @search="handleQuery()" />
                </div>
              </div>
            </a-col>
          </a-row>

          <a-spin :spinning="loadingTable">

            <div style="min-height: 400px;">
              <template>
                <div style="display: flex;flex-wrap: wrap;">
                  <div class="pictrueList_pic" v-for="(item, index) in listData">
                    <div class="picimage" @click="clickUploadFile(item.id)">
                      <img class="on" :src="item.imgUrl" lazy="loaded" />
                    </div>
                    <div class="picName">
                      <el-checkbox-group v-model="selectedRowKeys">
                        <el-checkbox :label="item.id">
                          <el-button type="text" @click="enitFileName(item)"><i class="el-icon-edit"></i></el-button>
                          {{ item.filenameSaved }}
                        </el-checkbox>
                      </el-checkbox-group>
                    </div>
                  </div>
                </div>
              </template>
            </div>

          </a-spin>

          <div class="footer acea-row row-right">
            <pagination :total="total" :current-page="queryParam.pageNum" :page-size="queryParam.pageSize"
              @currentChange="handleCurrentChange" @editPagesizes="editPageSizes" />
          </div>
        </a-col>
      </a-row>
    </a-modal>

    <!-- 上传图片 -->
    <a-modal title="上传图片" v-model:dialogPicturesVisible="dialogPicturesVisible" :modal-append-to-body="false"
      width="50%">
      <el-form label-width="70px" label-position="right">
        <!-- <el-form-item label="上传方式">
          <el-radio v-model="uploadForm.uploadType" :label="1">
            本地上传
          </el-radio>
        </el-form-item> -->

        <el-form-item label="上传分类">
          <el-select v-model="uploadForm.categoryId" placeholder="图片分类">
            <el-option v-for="item in treeList" :key="item.id" :label="item.categoryName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="上传图片">
          <div v-if="uploadForm.uploadType == 1" class="upload-style">
            <el-upload action="/api/vip/upload/public_pic" list-type="picture-card" :headers="headers"
              :data="uploadForm" :on-success="UploadOnSuccess">
              <i class="el-icon-plus"></i>
            </el-upload>
          </div>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogPicturesVisible = false">关闭</el-button>
      </span>
    </a-modal>

    <!-- 修改图片名称 -->
    <a-modal title="修改图片名称" v-model:dialogEnitFileNameVisible="dialogEnitFileNameVisible" :modal-append-to-body="false">
      <el-form label-position="left" label-width="70px">
        <el-form-item label="图片名称">
          <el-input v-model="uploadForm.filenameSaved"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitUpload()">确定</el-button>
        <el-button @click="dialogEnitFileNameVisible = false">取消</el-button>
      </span>
    </a-modal>

    <!-- 添加分类弹框 -->
    <a-modal title="新增分类" v-model:dialogInsertCateVisible="dialogInsertCateVisible" :modal-append-to-body="false">
      <el-form label-position="left" label-width="70px">
        <el-form-item label="分类名称">
          <el-input v-model="imageForm.categoryName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitInsertImageCate">确定</el-button>
        <el-button @click="dialogInsertCateVisible = false">取消</el-button>
      </span>
    </a-modal>

    <!-- 修改图片分类 -->
    <a-modal title="修改图片分类" v-model:dialogUpdateCateVisible="dialogUpdateCateVisible" :modal-append-to-body="false">
      <el-form label-position="left" label-width="70px">
        <el-form-item label="分类名称">
          <el-input v-model="imageForm.categoryName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onSubmitUpdateImageCate">确定</el-button>
        <el-button @click="dialogUpdateCateVisible = false">取消</el-button>
      </span>
    </a-modal>
  </div>
</template>
<script>
import {
  cateTreeList,
  getImageList,
  deleteImgeList,
  updateUploadfile
} from "@/api/image";
import {
  insertImageCate,
  updateImageCate,
  delImageCate,
  updateImageCateId
} from "@/api/image";

import { getToken, getUserId } from "@/utils/auth";
import { uploadLinkPic } from "@/api/upload";

import { mapState } from "vuex";
export default {
  data() {
    return {
      loadingTable: true,

      dialogVisible: false,
      dialogEnitFileNameVisible: false,
      selectedRowKeys: [],

      dialogPicturesVisible: false,

      categoryName: undefined,
      dialogInsertCateVisible: false,
      dialogUpdateCateVisible: false,
      headers: {
        token: getToken(),
        userId: getUserId()
      },
      linkPic: undefined,
      selectIndex: undefined,

      loadingTable: true,

      queryParam: {
        categoryId: 0,
        pageSize: 20,
        pageNum: 1,
        filenameSaved: undefined
      },
      uploadForm: {
        id: undefined,
        uploadType: 1,
        filenameSaved: undefined
      },

      imageForm: {
        id: undefined,
        categoryName: undefined
      },
      total: undefined,
      treeList: [],
      listData: []
    };
  },
  computed: {
    ...mapState({
      categoryList: state => state.product.cascaderCataList
    })
  },
  created() { },
  methods: {
    handleAdd() {
      this.dialogVisible = true;
      //加载图片分类
      this.queryCateTreeList();
      //加载图片列表
      this.queryImageList();
    },

    disableSelection(record) {
      return {
        props: {
          name: record.dockingStatus,

          //当状态是1的时候执行disable
          disabled: record.dockingStatus === 1
        }
      };
    },

    /**
     * 新增分类
     */
    insterImageCate() {
      this.dialogInsertCateVisible = true;
    },

    /**
     * 点击分类查询
     */
    handleTreeClick(categoryId) {
      this.queryParam.categoryId = categoryId;

      this.loadingTable = true;

      //加载图片列表
      this.queryImageList();
    },

    /**
     * 本地上传图片弹框
     */
    toPicturesImg() {
      this.dialogPicturesVisible = true;
    },

    /**
     * 修改图片名称
     */
    enitFileName(data) {
      this.uploadForm.id = data.id;
      this.uploadForm.filenameSaved = data.filenameSaved;

      this.dialogEnitFileNameVisible = true;
    },

    /**
     * 提交修改结果
     */
    onSubmitUpload() {
      updateUploadfile(this.uploadForm)
        .then(value => {
          this.$message({
            message: "操作成功",
            type: "success"
          });
          this.dialogEnitFileNameVisible = false;
          this.queryImageList();
        })
        .catch(() => {
          this.$message({
            message: "操作失败",
            type: "error"
          });
        });
    },

    /**
     * 点击图片
     */
    clickUploadFile(id) {
      (this.selectedRowKeys = []), this.selectedRowKeys.push(id);
    },

    /**
     * 加载图片分类树
     */
    queryCateTreeList() {
      new Promise((resolve, rejust) => {
        cateTreeList({})
          .then(res => {
            this.treeList = res.result;
            resolve();
          })
          .catch(error => {
            rejust(error);
          });
      });
    },

    /**
     * 新增分类
     */
    onSubmitInsertImageCate() {
      let data = {
        categoryName: this.imageForm.categoryName,
        categoryId: this.imageForm.id
      };
      new Promise((resolve, rejust) => {
        insertImageCate(data)
          .then(res => {
            this.queryCateTreeList();
            this.dialogInsertCateVisible = false;
            resolve();
          })
          .catch(error => {
            rejust(error);
          });
      });
    },

    /**
     * 外链上传
     */
    onSubmitLinkPic() {
      new Promise((resolve, rejust) => {
        uploadLinkPic(this.uploadForm)
          .then(res => {
            if (res.status == 0) {
              this.$message.success("上传成功");
              this.queryImageList();
            } else {
              this.$message.success("上传失败");
            }
            resolve();
          })
          .catch(error => {
            rejust(error);
          });
      });
    },

    /**
     * 修改图片分类
     */
    updateCategory(data) {
      this.dialogUpdateCateVisible = true;
      this.imageForm.id = data.id;
      this.imageForm.categoryName = data.categoryName;
    },

    /**
     * 修改商品分类
     */
    onSubmitUpdateImageCate() {
      new Promise((resolve, rejust) => {
        updateImageCate(this.imageForm)
          .then(res => {
            if (res.status == 0) {
              this.$message.success("修改成功");
              this.dialogUpdateCateVisible = false;

              this.queryCateTreeList();
            }
            resolve();
          })
          .catch(error => {
            rejust(error);
          });
      });
    },

    //删除图片分类
    remove(id) {
      new Promise((resolve, rejust) => {
        delImageCate(id)
          .then(res => {
            this.queryCateTreeList();
            resolve();
          })
          .catch(error => {
            rejust(error);
          });
      });
    },

    /**
     * 加载图片列表
     */
    queryImageList() {
      new Promise((resolve, rejust) => {
        getImageList(this.queryParam)
          .then(res => {
            this.listData = res.result.list;
            this.total = res.result.total;
            this.loadingTable = false;
            resolve();
          })
          .catch(error => {
            rejust(error);
          });
      });
    },

    batchDeleteImgList() {
      if (this.selectedRowKeys.length < 1) {
        this.$message({
          type: "error",
          message: "请选择要删除的图片!"
        });
        return;
      }
      this.$confirm("确认删除？")
        .then(async _ => {
          deleteImgeList(this.selectedRowKeys)
            .then(value => {
              this.$message({
                message: "删除成功",
                type: "success"
              });
              this.selectAll = !this.selectAll;
              this.queryImageList();
            })
            .catch(() => {
              this.$message({
                message: "删除失败",
                type: "error"
              });
            });
          this.queryImageList();
        })
        .catch(_ => { });
    },

    /**
     * 选择图片
     */
    selectImage() {
      if (this.selectedRowKeys.length > 0) {
        const [selectedId] = this.selectedRowKeys;
        const item = this.listData.find(item => item.id === selectedId);

        this.$emit("imageCallback", item);
        this.dialogVisible = false;
      } else {
        this.$message.error("请选择一张图片");
      }
    },

    /**
     * 根据参数搜索查询
     */
    handleQuery() {
      this.queryParam.pageSize = 10;
      this.queryParam.pageNum = 1;
      this.queryParam.categoryId = undefined;

      this.loadingTable = true;
      this.queryImageList();
    },

    // 修改当前页事件
    handleCurrentChange(pageNum) {
      this.loadingTable = true;
      this.queryParam.pageNum = pageNum;
      this.queryImageList();
    },
    // 修改分页的每页的条数
    editPageSizes(val) {
      this.loadingTable = true;
      this.queryParam.pageSize = val;
      this.queryImageList();
    },

    /**
     * 重置参数
     */
    resetQuery() {
      this.queryParam.productName = "";
      this.queryParam.categoryId = "";
    },


    UploadOnSuccess(res, file, fileList) {
      console.log(res, file, fileList)
      this.$message.success("上传成功");
      this.queryImageList();
      // this.dialogPicturesVisible = false;
      this.$forceUpdate();
    },
    beforeAvatarUpload(file) {
      const isImg =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/gif";
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isImg) {
        this.$message.error("上传图片只能是 JPG、PNG、GIF 格式之一!");
      }
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
      }
      return isImg && isLt20M;
    }
  }
};
</script>
<style>
.dock_border {
  max-height: 300px;
  overflow-y: auto;
  scrollbar-color: rgb(136, 136, 136) rgb(240, 240, 240);
  scrollbar-width: thin;
  border: 1px solid #e7d7db;
  padding: 10px;
  border-radius: 5px;
}

.img_box .list-box {
  min-height: 50vh;
}

.img_box .list-box .pic-item {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 125px;
  padding: 10px 50px 100px 0px;
  margin: 10px 15px;
  vertical-align: bottom;
  cursor: pointer;
  border-radius: 3px;
}

.img_box .list-box .pic-item .number {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 33px;
}

.img_box .list-box .pic-item .number .badge {
  display: inline-block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #f56c6c;
  border-radius: 50%;
}

.img_box .list-box .pic-item .image {
  width: 100px;
  height: 100px;
  background-color: #f0f0f0 !important;
}

.img_box .list-box .pic-item .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.img_box .list-box .pic-item .image .on {
  border: 2px solid #5fb878;
}

.img_box .list-box .pic-item .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  background-color: #fff !important;
}

.img_box .list-box .pic-item .name p {
  height: 20px;
  padding: 0;
  margin: 0;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.img_box .list-box .pic-item .name p .el-input__inner {
  height: 20px;
  line-height: 20px;
}

.img_box .list-box .pic-item .name .pic-menu {
  position: absolute;
  bottom: 22px;
  left: 0;
  display: none;
  width: 100px;
  text-align: center;
}

.img_box .list-box .pic-item .name .pic-menu .el-button-group {
  zoom: 0.7;
}

.img_box .list-box .pic-item .name:hover .pic-menu {
  display: block;
}

.img_box .list-box .vab-data-empty {
  min-height: 300px;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/* =======================================新的======================================== */

.w10 {
  width: 10px;
}

.mr5 {
  margin-right: 5px;
}

.layout-breadcrumb-seting-bar {
  padding: 0 15px;
}

.layout-breadcrumb-seting-bar .el-scrollbar__view {
  overflow-x: hidden;
}

.layout-breadcrumb-seting-bar .layout-breadcrumb-seting-bar-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.layout-breadcrumb-seting-bar .layout-breadcrumb-seting-bar-flex-label {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: var(--prev-color-text-primary);
}

.layout-breadcrumb-seting-bar .layout-drawer-content-flex {
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 -5px;
}

.layout-breadcrumb-seting-bar .layout-drawer-content-flex .layout-drawer-content-item.drawer-layout-active {
  border: 1px solid;
  border-color: var(--prev-color-primary);
}

.layout-breadcrumb-seting-bar .layout-drawer-content-flex .layout-drawer-content-item:hover {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 1px solid;
  border-color: var(--prev-color-primary);
}

.layout-breadcrumb-seting-bar .layout-drawer-content-flex .layout-drawer-content-item {
  width: 107px;
  height: 70px;
  cursor: pointer;
  border: 1px solid transparent;
  position: relative;
  padding: 6px;
  background: #fff;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  opacity: 1;
  margin: 10px;
}

.ivu-modal-wrap ::v-deep .connect_customerServer_img {
  display: none;
}

.right-box .ivu-color-picker .ivu-select-dropdown {
  position: absolute;
  left: -73px !important;
}

.nameStyle {
  position: absolute;
  white-space: nowrap;
  z-index: 9;
  background: #eee;
  height: 20px;
  line-height: 20px;
  color: #555;
  border: 1px solid #ebebeb;
  padding: 0 5px;
  left: 56px;
  bottom: -18px;
}

.iconbianji1 {
  font-size: 13px;
}

.selectTreeClass {
  background: #d5e8fc;
}

.tree_tit {
  padding-top: 7px;
}

.pictrueList_pic .number {
  height: 33px;
  position: absolute;
  right: 0;
  top: 0;
}

.pictrueList_pic .el-badge__content.is-fixed {
  top: 13px;
  right: 25px;
}

.Nav {
  width: 100%;
  border-right: 1px solid #eee;
  padding-right: 10px;
}

.trees-coadd {
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.trees-coadd .scollhide {
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0 0 10px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.trees-coadd .scollhide .isTree {
  min-height: 374px;
  max-height: 550px;
}

.trees-coadd .scollhide .isTree .file-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.trees-coadd .scollhide .isTree .file-name .name {
  max-width: 7em;
}

.trees-coadd .scollhide .isTree .file-name .icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
}

.trees-coadd .scollhide .isTree .el-tree-node {
  margin-right: 16px;
}

.trees-coadd .scollhide .isTree .el-tree-node__children .el-tree-node {
  margin-right: 0;
}

.trees-coadd .scollhide .isTree .el-tree-node__content {
  width: 100%;
  height: 36px;
}

.trees-coadd .scollhide .isTree .custom-tree-node {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 20px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 13px;
}

.trees-coadd .scollhide .isTree .is-current {
  background: #f1f9ff !important;
  color: var(--prev-color-primary) !important;
}

.trees-coadd .scollhide .isTree .is-current .custom-tree-node {
  color: var(--prev-color-primary) !important;
}

.trees-coadd .scollhide::-webkit-scrollbar {
  display: none;
}

.treeSel .ivu-select-dropdown-list {
  padding: 0 5px !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 200px;
}

.imagesNo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 65px 0;
}

.imagesNo .imagesNo_sp {
  font-size: 13px;
  color: #dbdbdb;
  line-height: 3;
}

.fill-window {
  height: 100vh;
}

.colLeft {
  padding-right: 0 !important;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.material_center {
  width: 100%;
  height: 100%;
  margin-left: 20px !important;
}

.material_center .iconliebiao {
  font-size: 12px;
}

.material_center .bnt {
  width: 100%;
  padding: 0 13px 20px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.material_center .pictrueList {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 463px;
}

.material_center .pictrueList.is-modal {
  max-height: 480px;
}

.material_center .pictrueList img {
  max-width: 100%;
}

.material_center .pictrueList .img.on {
  border: 2px solid var(--prev-color-primary);
}

.material_center .footer {
  padding: 0 20px 10px 20px;
}

.tabBox_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.real-name {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.df-aic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.demo-badge {
  width: 42px;
  height: 42px;
  background: transparent;
  border-radius: 6px;
  display: inline-block;
}

.bnt .ivu-tree-children {
  padding: 5px 0;
}

.card-tree {
  background: #fff;
  height: 72px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
  border-radius: 4px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.card-tree::-webkit-scrollbar {
  display: none;
}

.operate-item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 5px 0;
}

.operate-height,
.operate-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.operate-height {
  height: 16px;
  position: absolute;
  bottom: -10px;
}

.w-80 {
  width: 80px;
}

.w-100 {
  width: 100px;
}

.vxe-cell,
.vxe-tree-cell {
  font-size: 12px;
}

.c_label {
  font-size: 14px;
  color: #999;
}

.c_label span {
  margin-left: 10px;
  color: #333;
}

.c_row-item {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.acea-row,
.c_row-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.acea-row {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.acea-row.row-middle {
  -webkit-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.acea-row.row-top {
  -webkit-box-align: start;
  -o-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.acea-row.row-bottom {
  -webkit-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.acea-row.row-center {
  -webkit-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mobile-config {
  width: 100%;
  padding: 15px;
}

.ivu-layout-content {
  overflow-x: hidden !important;
  height: 100%;
}

.page {
  margin-top: 22px;
}

.h-33 {
  height: 33px;
}

.mr {
  margin-right: 15px;
}

.mr10 {
  margin-right: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml10 {
  margin-left: 10px;
}

.ml15 {
  margin-left: 15px;
}

.ml40 {
  margin-left: 40px !important;
}

.ml95 {
  margin-left: 95px !important;
}

.pt5 {
  padding: 5px 0 !important;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.mr50 {
  margin-right: 50px;
}

.mr20 {
  margin-right: 20px !important;
}

.mr15 {
  margin-right: 15px !important;
}

.mr5 {
  margin-right: 5px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mb14 {
  margin-bottom: 14px !important;
}

.mb15 {
  margin-bottom: 15px !important;
}

.mb30 {
  margin-bottom: 30px !important;
}

.mt3 {
  margin-top: 3px;
}

.mt35 {
  margin-top: 35px;
}

.mt30 {
  margin-top: 30px;
}

.mt25 {
  margin-top: 25px;
}

.mt20 {
  margin-top: 20px;
}

.mt14 {
  margin-top: 14px;
}

.mt50 {
  margin-top: 50px;
}

.mt10 {
  margin-top: 10px;
}

.mb10 {
  margin-bottom: 10px !important;
}

.spBlock {
  display: block;
}

.padding-add {
  padding: 20px 20px 0;
}

.i-layout-menu-side-title-icon-single .ivu-icon {
  font-size: 20px;
}

.ivu-layout-content {
  position: relative;
}

.font-blue:hover {
  color: #2d8cf0;
  cursor: pointer;
}

.i-layout-tabs-fix {
  z-index: 5 !important;
}

.vxe-header--row th:first-of-type,
.vxe-table--body td:first-of-type {
  padding-left: 10px !important;
}

.ivu-modal-header {
  background: #fafafa;
}

.ivu-mb,
.ivu-mb-16 {
  margin-bottom: 16px !important;
}

.ivu-mt-16 {
  margin-top: 16px !important;
}

.i-layout-page-header {
  margin: 0;
  background-color: #fff;
  padding: 7px 32px 0 32px;
}

.header-title {
  padding: 10px 20px;
  margin-bottom: 16px;
  border-radius: 5px;
  font-size: 14px;
}

.header-title,
.statics-header-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #303133;
}

.statics-header-title {
  font-size: 16px;
}

.ivu-page-header-title {
  display: inline-block;
  color: #17233d;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.main .content-wrapper {
  padding: 16px;
  height: calc(100% - 80px);
  overflow: auto;
  background-color: #f5f7f9;
}

.left-wrapper {
  height: calc(100vh - 120px);
  background: #fff;
  border-right: 1px solid #f2f2f2;
}

.ivu-tabs-bar {
  margin-bottom: 0;
}

.perW100 {
  width: 100%;
}

.perW90 {
  width: 90%;
}

.perW50 {
  width: 50%;
}

.perW20 {
  width: 20%;
}

.perW35 {
  width: 35%;
}

.perW30 {
  width: 30%;
}

.font12 {
  font-size: 12px !important;
}

.font14 {
  font-size: 14px !important;
}

.ivu-text-left {
  text-align: left;
}

.ivu-text-right {
  text-align: right;
}

.ivu-block {
  display: block;
}

.ivu-pl-8 {
  padding-left: 8px !important;
}

.tabBox_img {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
}

.tabBox_img img {
  width: 100%;
  height: 100%;
}

.mask-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-top: 20px;
  border-top: 1px solid #e8eaec;
}

.mask-footer button {
  margin-left: 10px;
}

.goods-mask .ivu-modal-body {
  padding: 0;
}



.none-radius .ivu-modal-content {
  border-radius: 0;
}

.transfer-mask .ivu-modal-wrap {
  width: 1200px;
  margin: 0 auto;
}

.transfer-mask .ivu-modal {
  position: absolute;
  right: 279px;
  top: auto;
  bottom: 271px;
}

.maskModel {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 55;
  background-color: rgba(0, 0, 0, 0.5);
}

.input-input {
  display: block;
  height: 100%;
  background: none;
  color: inherit;
  opacity: 1;
  -webkit-text-fill-color: currentcolor;
  font: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  text-align: inherit;
  text-indent: inherit;
  text-transform: inherit;
  text-shadow: inherit;
  border: none;
}

.kf_mobile .textarea-box textarea {
  resize: none !important;
  height: 148px;
  border-color: transparent;
  font-size: 14px !important;
}

.kf_mobile .textarea-box textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.line1 {
  white-space: nowrap;
}

.df-n-warp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.label-box .ivu-modal-mask,
.none-radius .ivu-modal-mask {
  background-color: transparent;
}

.empty-box {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: #f3f5f7;
  border-radius: 6px;
}

.empty-box.on {
  border-radius: 0;
}

.empty-box .iconfont-diy {
  color: #bbbfc8;
  font-size: 30px;
}

.paddingBox {
  padding: 0 10px 10px;
}

.common-form-button {
  margin-right: 10px;
}

.df-jcsb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.tree_tit {
  font-size: 15px;
  color: #606266;
  font-weight: 500;
  padding: 23px 0 23px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.tree_tit .el-icon-circle-plus {
  color: #c0c4cc;
  font-size: 20px;
  margin-right: 6px;
}

.tree {
  min-height: 374px;
}

.tree .file-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #303133;
  font-size: 14px;
}

.tree .file-name .icon {
  width: 15px;
  height: 13px;
  margin-right: 6px;
}

.tree .el-tree-node__children .el-tree-node {
  margin-right: 0;
}

.tree .el-tree-node__content {
  width: 100%;
  height: 48px;
}

.tree .custom-tree-node {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-right: 20px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 13px;
}

.tree .is-current {
  background: #f1f9ff !important;
}

.tree .el-tree--highlight-current .el-tree-node.is-current {
  background-color: #e5eeff;
  color: #0256ff;
  border-right: 2px solid #0256ff;
}

.pictrueList_pic {
  position: relative;
  width: 146px;
  cursor: pointer;
}

.pictrueList_pic .img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 146px;
  height: 146px;
  background-color: #f8f8f8;
  padding: 3px;
}

.pictrueList_pic .img img {
  max-width: 100px;
  max-height: 100px;
}

.pictrueList_pic .el-badge__content.is-fixed {
  top: 13px;
  right: 25px;
}

.imagesNo .imagesNo_sp {
  font-size: 13px;
  color: #dbdbdb;
  line-height: 3;
}

.fill-window {
  height: 100vh;
}

.material_center {
  width: 100%;
  height: 100%;
  margin-left: 20px !important;
}

.material_center .iconliebiao {
  font-size: 12px;
}

.material_center .bnt {
  width: 100%;
  padding: 0 13px 20px 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.material_center .pictrueList {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 463px;
}

.material_center .pictrueList.is-modal {
  max-height: 480px;
}

.material_center .pictrueList img {
  max-width: 100%;
}

.material_center .footer {
  padding: 0 20px 10px 20px;
}

.tabBox_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.real-name {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.df-aic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.demo-badge {
  width: 42px;
  height: 42px;
  background: transparent;
  border-radius: 6px;
  display: inline-block;
}

.bnt .ivu-tree-children {
  padding: 5px 0;
}

.card-tree {
  background: #fff;
  height: 72px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-y: hidden;
  border-radius: 4px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.card-tree::-webkit-scrollbar {
  display: none;
}

.operate-item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 5px 0;
}

.operate-height,
.operate-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.operate-height {
  height: 16px;
  position: absolute;
  bottom: -10px;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.el-tree-node__content {
  height: 40 !important;
}

/* 图片的 */

.file-wraper {
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}

.file-wraper div:not(.text-wrap) {
  display: -webkit-flex;
  display: flex;
}

.file-wraper .img-container {
  border-radius: 4px;
  overflow: hidden;
}

.file-wraper .cover-picture {
  object-fit: scale-down;
  background: #fafafa;
  width: 80px;
  height: 60px;
  cursor: pointer;
}

.file-wraper .documentation-picture {
  width: 40px;
  height: 40px;
  background: #fff;
}

.file-wraper .file-info {
  -webkit-flex-direction: column;
  flex-direction: column;
  margin-left: 8px;
  -webkit-flex: 1;
  flex: 1;
}

.file-wraper .file-info .file-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-wraper .file-info .file-name.special-name {
  max-width: 150px;
}

.file-wraper .file-info .file-name .file-name-title {
  display: inline-block;
  max-width: calc(100% - 20px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}

.file-wraper .file-info .file-name .edit-btn-wrap {
  width: 21px;
  height: 21px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1;
  flex: 1;
}

.file-wraper .file-info .file-name .edit-btn-wrap .edit-btn {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: 16px;
  height: 21px;
  background-size: 16px;
  -webkit-transform: translate(5px, 4px);
  transform: translate(5px, 4px);
  cursor: pointer;
}

.file-wraper .file-info .file-name .edit-btn-wrap .edit-btn:hover {
  background-size: 16px;
}

.file-wraper .file-info .file-size {
  font-size: 12px;
  font-weight: 400;
  color: #999;
}

.el-tree-node__content {
  height: 40px !important;
}

/* 图片新 */

.pictrueList_pic {
  position: relative;
  width: 146px;
  margin: 0 19px 22px 0;
  cursor: pointer;
}

.pictrueList_pic .picimage {
  width: 146px;
  height: 146px;
  background-color: #f0f0f0 !important;
}

.pictrueList_pic .picimage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10px;
}

.pictrueList_pic .picName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #515a6d;
}

.pictrueList_pic p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  color: #515a6d;
}

.pictrueList_pic .number {
  height: 33px;
  position: absolute;
  right: 0;
  top: 0;
}

.pictrueList {
  width: 100%;
  height: 100%;
}

.pictrueList img {
  width: 100%;
  vertical-align: middle;
}

.pictrueList img.on {
  border: 2px solid #5fb878;
}

.upload-style .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}

.upload-style .el-upload--picture-card {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
}

.upload-style .el-upload:hover {
  border-color: #409eff;
}

.upload-icon {
  font-size: 28px;
  color: #8c939d;
  width: 92px;
  height: 92px;
  line-height: 92px;
  text-align: center;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: 600px;
}
</style>
